import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { Image } from '@/core/Image/Image';
import { isB2B } from '@/core/settings/utils';
import { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { PartnerAdBannerLabel } from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/PartnerAdBannerLabel/PartnerAdBannerLabel';
import {
  defaultThemeColors,
  ThemeColors,
} from '@/sellerAdsManagement/PartnerAdBannerCampaign/interfaces/themeColors';
import { usePartnerAdTheme } from '@/sellerAdsManagement/PartnerAdTheme/hooks/usePartnerAdTheme';

import styles from './PromoBlock.module.scss';

export interface PromoBlockProps {
  banner: BannerCampaign;
}

export const PromoBlock: FunctionComponent<PromoBlockProps> = ({ banner }) => {
  const theme = usePartnerAdTheme();

  const themeColors = {
    '--banner-main-color':
      theme && theme.colors ? theme.colors.main : defaultThemeColors.main,
    '--banner-accent-color':
      theme && theme.colors ? theme.colors.accent : defaultThemeColors.accent,
  };

  return (
    <div
      className={classnames(styles.container, { [styles.isB2B]: isB2B() })}
      style={themeColors as ThemeColors}
    >
      <Image
        src={banner.image.mobileUrl}
        alt={banner.image.altText}
        title={banner.name}
        width={280}
        height={280}
        className={styles.image}
      />
      {banner.label && (
        <div className={styles.labelContainer}>
          <PartnerAdBannerLabel
            label={banner.label}
            subtitleClassName={styles.subtitle}
            lineClassName={styles.line}
            middleLineClassName={styles.middleLine}
            smallLineClassName={styles.smallLine}
            bigLineClassName={styles.bigLine}
            labelClassName={styles.label}
            subtitle={banner.subtitle}
          />
          {banner.logoUrl && (
            <div className={styles.brand}>
              <Image
                className={styles.brandImage}
                src={banner.logoUrl}
                alt="brand-logo"
                height={undefined}
                width={undefined}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

PromoBlock.displayName = 'PromoBlock';
