import classnames from 'classnames';
import { type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { InfoOutline as InfoIcon } from '@/core/tamagoshi/icons/InfoOutline';
import { Text } from '@/core/tamagoshi/Text/Text';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { displayAdvertiserInformation } from '@/domains/productDiscovery/CommercialAnimation/CommercialAnimation.actions';
import type { AdvertiserInformation } from '@/domains/productDiscovery/CommercialAnimation/interfaces/AdvertiserInformation';
import { createAdvertiserInformationPopupContent } from '@/domains/productDiscovery/CommercialAnimation/utils/createAdvertiserInformationPopupContent';
import { GTM_DISPLAY_POPIN_INFO } from '@/productDiscovery/CommercialAnimation/services/tracking/displayPopinInfo';
import { getSponsoredLabelText } from '@/sellerAdsManagement/sponsoredLabelText/sponsoredLabelText';

import popupStyles from './AdvertiserInformationPopup.module.scss';
import styles from './BannerCampaignAdvertiserLabel.module.scss';

export interface BannerCampaignAdvertiserLabelProps {
  className?: string;
  sponsor?: string | null;
  advertiser?: string | null;
  userSegments?: {
    [key: string]: string[];
  };
  withRadius?: boolean;
  spaceSlug?: string;
  pageType?: string;
}

export const BannerCampaignAdvertiserLabel: FunctionComponent<
  BannerCampaignAdvertiserLabelProps
> = ({
  className,
  sponsor,
  advertiser,
  userSegments,
  spaceSlug,
  pageType,
  withRadius = true,
}) => {
  const rootClassnames = classnames(styles.root, className, {
    [styles.withRadius]: withRadius,
  });
  const dispatch = useDispatch();

  const advertiserInformation: AdvertiserInformation = {
    sponsor,
    advertiser,
    userSegments: !!userSegments,
    dsaPopup: !!(sponsor || advertiser),
    content: createAdvertiserInformationPopupContent(
      advertiser || '',
      sponsor || '',
      !!userSegments,
      popupStyles.content,
    ),
  };

  return (
    <div className={rootClassnames}>
      <InfoIcon className={styles.infoIcon} />
      <Text
        tag="span"
        type="small"
        className={styles.label}
        onClick={(e: any) => {
          e.preventDefault();
          dispatch(displayAdvertiserInformation(advertiserInformation));
          Gtm.push(GTM_DISPLAY_POPIN_INFO(spaceSlug || '', pageType || ''));
        }}
        suppressHydrationWarning
      >
        {getSponsoredLabelText()}
      </Text>
    </div>
  );
};

BannerCampaignAdvertiserLabel.displayName = 'BannerCampaignAdvertiserLabel';
