import { SyntheticEvent, type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { Typography } from '@/core/tamagoshiv2/components/Typography/Typography';
import { InfoOutlineIcon } from '@/core/tamagoshiv2/icons/system/InfoOutlineIcon';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { displayAdvertiserInformation } from '@/domains/productDiscovery/CommercialAnimation/CommercialAnimation.actions';
import { createAdvertiserInformationPopupContent } from '@/domains/productDiscovery/CommercialAnimation/utils/createAdvertiserInformationPopupContent';
import { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { GTM_DISPLAY_POPIN_INFO } from '@/productDiscovery/CommercialAnimation/services/tracking/displayPopinInfo';
import { fetchDSAByProductId } from '@/sellerAdsManagement/PartnerAdBannerCampaign/services/fetchDSAByProductId';
import { getSponsoredLabelText } from '@/sellerAdsManagement/sponsoredLabelText/sponsoredLabelText';

import popupStyles from '@/productDiscovery/CommercialAnimation/components/AdvertiserInformation/AdvertiserInformationPopup.module.scss';
import styles from './PartnerAdBannerCampaignAdvertiserLabel.module.scss';

export interface BannerCampaignAdvertiserLabelProps {
  partnerAdBannerCampaign: BannerCampaign;
  spaceSlug?: string;
}

export const PartnerAdBannerCampaignAdvertiserLabel: FunctionComponent<
  BannerCampaignAdvertiserLabelProps
> = ({ partnerAdBannerCampaign, spaceSlug }) => {
  const dispatch = useDispatch();

  const onAdvertiserLabelClicked = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (
      partnerAdBannerCampaign &&
      partnerAdBannerCampaign.productIds &&
      partnerAdBannerCampaign.productIds.length
    ) {
      const dsaData = await fetchDSAByProductId(
        partnerAdBannerCampaign.productIds[0],
      );

      if (dsaData) {
        const sponsor = dsaData.seller?.contractName ?? '';
        const advertiser = dsaData.sku?.masterProduct?.brand?.title ?? '';

        const adInfo = {
          sponsor,
          advertiser,
          userSegments: false,
          dsaPopup: !!(sponsor || advertiser),
          content: createAdvertiserInformationPopupContent(
            advertiser,
            sponsor,
            false,
            popupStyles.content,
          ),
        };

        dispatch(displayAdvertiserInformation(adInfo));
        Gtm.push(
          GTM_DISPLAY_POPIN_INFO(
            spaceSlug || '',
            partnerAdBannerCampaign.pageType || '',
          ),
        );
      }
    }
  };

  return (
    <button
      className={styles.root}
      onClick={onAdvertiserLabelClicked}
      data-testid="info-label"
      suppressHydrationWarning
    >
      <InfoOutlineIcon className={styles.info} />
      <Typography asComponent="span" variant="small" className={styles.label}>
        {getSponsoredLabelText()}
      </Typography>
    </button>
  );
};

PartnerAdBannerCampaignAdvertiserLabel.displayName =
  'BannerCampaignAdvertiserLabel';
