import classnames from 'classnames';
import { type FunctionComponent } from 'react';

export interface LabelProps {
  label: {
    line1?: string;
    line2?: string;
    format: 'BIG_SMALL' | 'MIDDLE_MIDDLE' | 'SMALL_BIG';
  };
  subtitle?: string;
  labelClassName: string;
  lineClassName: string;
  bigLineClassName: string;
  middleLineClassName: string;
  smallLineClassName: string;
  secondLineClassName?: string;
  subtitleClassName: string;
}

export const PartnerAdBannerLabel: FunctionComponent<LabelProps> = ({
  label,
  subtitle,
  labelClassName,
  lineClassName,
  bigLineClassName,
  middleLineClassName,
  smallLineClassName,
  secondLineClassName,
  subtitleClassName,
}) => {
  return (
    <div className={labelClassName}>
      <span
        className={classnames(lineClassName, {
          [bigLineClassName]: label.format === 'BIG_SMALL',
          [smallLineClassName]: label.format === 'SMALL_BIG',
          [middleLineClassName]: label.format === 'MIDDLE_MIDDLE',
        })}
      >
        {label.line1}
      </span>
      <span
        className={classnames(
          lineClassName,
          {
            [smallLineClassName]: label.format === 'BIG_SMALL',
            [bigLineClassName]: label.format === 'SMALL_BIG',
            [middleLineClassName]: label.format === 'MIDDLE_MIDDLE',
          },
          secondLineClassName,
        )}
      >
        {label.line2}
      </span>
      <span className={subtitleClassName}>{subtitle || ''}</span>
    </div>
  );
};

PartnerAdBannerLabel.displayName = 'PartnerAdBannerLabel';
