import { httpClient } from '@/core/httpClient/httpClient';
import { getAppSetting } from '@/core/settings/appSettings';
import { getPlatform, isB2C } from '@/core/settings/utils';
import { Channel } from '@/generated/graphql/types';
import { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

const BASE_PATH = '/seller-ads-management/api/v1/partner-ads-event/';

export const trackPartnerAdBannerCampaignImpression = (
  campaign: BannerCampaign,
  visitorId: string,
) => {
  const payload = {
    channel: Channel.WEB,
    id: campaign.id,
    market: isB2C() ? 'B2C' : 'B2B',
    page_type: campaign.pageType,
    platform: getPlatform().toUpperCase(),
    session_id: visitorId,
    space: campaign.space,
    tracking_id: campaign.trackingId || '',
  };

  httpClient
    .post<void>(`${BASE_PATH}view`, payload, {
      baseURL: getAppSetting('MS_API_URL'),
    })
    .catch((err: Error) => {});
};

export const trackPartnerAdBannerCampaignClick = (
  campaign: BannerCampaign,
  visitorId: string,
) => {
  const payload = {
    channel: Channel.WEB,
    id: campaign.id,
    market: isB2C() ? 'B2C' : 'B2B',
    page_type: campaign.pageType,
    platform: getPlatform().toUpperCase(),
    session_id: visitorId,
    space: campaign.space,
    tracking_id: campaign.trackingId || '',
  };

  httpClient.post<void>(`${BASE_PATH}interaction`, payload, {
    baseURL: getAppSetting('MS_API_URL'),
  });
};
