/* eslint-disable camelcase */
import { FlagValuesMap } from '@/core/flags/flags.state';
import type {
  ABTestModel,
  FeatureFlagModel,
} from '@/domains/productDiscovery/CommercialAnimation/interfaces/ABFFlags';

export const abTestAndFeatureFlagValidated = (
  argument: {
    abTest?: ABTestModel;
    featureFlag?: FeatureFlagModel;
  },
  flags: FlagValuesMap,
): boolean => {
  if (argument.abTest) {
    const abTest = !!flags[argument.abTest.id];
    if (abTest !== (argument.abTest.feature === 'b')) {
      return false;
    }
  }

  if (argument.featureFlag) {
    const featureFlag = !!flags[argument.featureFlag.id];
    if (featureFlag !== argument.featureFlag.active) {
      return false;
    }
  }

  return true;
};

export const abTestAndFeatureFlagEnabledValidated = (
  argument: {
    abTest?: ABTestModel;
    featureFlag?: FeatureFlagModel;
  },
  flags: FlagValuesMap,
): boolean => {
  if (argument.abTest) {
    const abTest = !!flags[argument.abTest.id];
    if (abTest !== (argument.abTest.feature === 'b')) {
      return false;
    }
  }

  if (argument.featureFlag) {
    const featureFlag = !!flags[argument.featureFlag.id];
    if (featureFlag !== argument.featureFlag.active) {
      return false;
    }
  }

  return true;
};
