import { queryData } from '@/core/graphqlClient/queryClient';
import logger from '@/core/observability/logger';
import { getPlatform, isB2B } from '@/core/settings/utils';
import { DsaByProductIdDocument } from '@/domains/sellerAdsManagement/PartnerAdBannerCampaign/services/graphql/DSAByProductId/generated/dsaByProductId.query.generated';
import { Platform } from '@/generated/graphql/types';

export const fetchDSAByProductId = (productId?: string) => {
  return queryData(DsaByProductIdDocument, {
    platform: getPlatform().toUpperCase() as Platform,
    market: isB2B() ? 'B2B' : 'B2C',
    productId,
  })
    .then((result: any) => {
      return result.product;
    })
    .catch((e) => {
      logger.error(e.message);
      return undefined;
    });
};
