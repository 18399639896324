import classnames from 'classnames';
import { useState, type FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { Link } from '@/core/routing/components/Link';
import { useTrackViewedElement } from '@/core/tracking/hooks/useTrackViewedElement';
import { selectVisitorId } from '@/core/tracking/selectors/selectVisitorId';
import { Gtm } from '@/core/tracking/utils/Gtm';
import { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { GTM_CLICK_ON_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/clickOnBannerCampaign';
import { GTM_DISPLAY_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/displayBannerCampaign';
import { BrandBlock } from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/BrandBlock/BrandBlock';
import { ListingBanner } from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/ListingBanner/ListingBanner';
import { PartnerAdBannerCampaignAdvertiserLabel } from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/PartnerAdBannerCampaignAdvertiserLabel/PartnerAdBannerCampaignAdvertiserLabel';
import { PromoBlock } from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/PromoBlock/PromoBlock';
import { RectangularBlock } from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/RectangularBlock/RectangularBlock';
import { spaceSlugType } from '@/sellerAdsManagement/PartnerAdBannerCampaign/interfaces/spaceSlugTypes';
import {
  trackPartnerAdBannerCampaignClick,
  trackPartnerAdBannerCampaignImpression,
} from '@/sellerAdsManagement/PartnerAdBannerCampaign/services/tracking/api';

import styles from './PartnerAdBanner.module.scss';

export interface PartnerAdBannerProps {
  partnerAdBannerCampaign: BannerCampaign;
  bannerTypeTracking: string;
  spaceSlug: spaceSlugType;
  containerClassName?: string;
  linkClassName?: string;
}

type ComponentProps = {
  banner: BannerCampaign;
};

export const PartnerAdBanner: FunctionComponent<PartnerAdBannerProps> = ({
  partnerAdBannerCampaign,
  bannerTypeTracking,
  spaceSlug,
  containerClassName,
  linkClassName,
}) => {
  const [impressionDone, setImpressionDone] = useState(false);
  const visitorId = useSelector(selectVisitorId);

  const moduleComponentsMap: Record<
    string,
    {
      component: ({ banner }: ComponentProps) => React.ReactNode;
    }
  > = {
    ListingBanner: { component: ListingBanner },
    BrandBlock: { component: BrandBlock },
    PromoBlock: { component: PromoBlock },
    RectangularBlock: { component: RectangularBlock },
  };

  const renderItem = (item: string) => {
    const Block = moduleComponentsMap[item].component;

    return <Block key={item} banner={partnerAdBannerCampaign} />;
  };

  const [campaignRef] = useTrackViewedElement(
    () => {
      trackPartnerAdBannerCampaignImpression(
        partnerAdBannerCampaign,
        visitorId,
      );

      Gtm.push(
        GTM_DISPLAY_BANNER_CAMPAIGN(
          bannerTypeTracking,
          partnerAdBannerCampaign.id,
          partnerAdBannerCampaign.name,
          partnerAdBannerCampaign.link && partnerAdBannerCampaign.link.url
            ? partnerAdBannerCampaign.link.url
            : '',
          0,
          true,
          partnerAdBannerCampaign.pageType || undefined,
          partnerAdBannerCampaign.trackingId || undefined,
          'Unlimitail',
        ),
      );
      setImpressionDone(true);
    },
    { delay: 1000, threshold: 0.5 },
  );

  const trackClick = () => {
    if (!impressionDone) {
      trackPartnerAdBannerCampaignImpression(
        partnerAdBannerCampaign,
        visitorId,
      );
    }
    trackPartnerAdBannerCampaignClick(partnerAdBannerCampaign, visitorId);

    if (!impressionDone) {
      Gtm.push(
        GTM_DISPLAY_BANNER_CAMPAIGN(
          bannerTypeTracking,
          partnerAdBannerCampaign.id,
          partnerAdBannerCampaign.name,
          partnerAdBannerCampaign.link.url || '',
          0,
          true,
          partnerAdBannerCampaign.pageType || undefined,
          partnerAdBannerCampaign.trackingId || undefined,
          'Unlimitail',
        ),
      );
    }

    Gtm.push(
      GTM_CLICK_ON_BANNER_CAMPAIGN(
        bannerTypeTracking,
        partnerAdBannerCampaign.id,
        partnerAdBannerCampaign.name,
        partnerAdBannerCampaign.link.url || '',
        0,
        true,
        partnerAdBannerCampaign.pageType || undefined,
        partnerAdBannerCampaign.trackingId || undefined,
        'Unlimitail',
      ),
    );

    setImpressionDone(true);
  };

  return (
    <div className={classnames(styles.banner, containerClassName)}>
      <PartnerAdBannerCampaignAdvertiserLabel
        partnerAdBannerCampaign={partnerAdBannerCampaign}
        spaceSlug={bannerTypeTracking}
      />
      <Link
        data-testid="link"
        href={
          partnerAdBannerCampaign.link && partnerAdBannerCampaign.link.url
            ? partnerAdBannerCampaign.link.url
            : ''
        }
        target="_self"
        ref={campaignRef}
        onClick={trackClick}
        className={classnames(styles.root, linkClassName)}
      >
        {renderItem(spaceSlug)}
      </Link>
    </div>
  );
};

PartnerAdBanner.displayName = 'PartnerAdBanner';
