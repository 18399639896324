import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { Image } from '@/core/Image/Image';
import { isB2B } from '@/core/settings/utils';
import { Span } from '@/domains/core/tamagoshiv2/components/Typography/Typography';
import { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import {
  defaultThemeColors,
  ThemeColors,
} from '@/sellerAdsManagement/PartnerAdBannerCampaign/interfaces/themeColors';
import { usePartnerAdTheme } from '@/sellerAdsManagement/PartnerAdTheme/hooks/usePartnerAdTheme';

import styles from './BrandBlock.module.scss';

export interface BrandBlockProps {
  banner: BannerCampaign;
}

const SIZES = {
  small: {
    ldpi: {
      width: 158,
      height: 100,
    },
    hdpi: {
      width: 316,
      height: 200,
    },
  },
  medium: {
    ldpi: {
      width: 158,
      height: 100,
    },
    hdpi: {
      width: 316,
      height: 200,
    },
  },
  large: {
    ldpi: {
      width: 225,
      height: 142,
    },
    hdpi: {
      width: 450,
      height: 284,
    },
  },
};

export const BrandBlock: FunctionComponent<BrandBlockProps> = ({ banner }) => {
  const theme = usePartnerAdTheme();

  const themeColors = {
    '--banner-main-color':
      theme && theme.colors ? theme.colors.main : defaultThemeColors.main,
    '--banner-accent-color':
      theme && theme.colors ? theme.colors.accent : defaultThemeColors.accent,
    '--banner-labor_text-color':
      theme && theme.colors
        ? theme.colors.labor_text
        : defaultThemeColors.labor_text,
    '--banner-nut-color':
      theme && theme.colors ? theme.colors.nut : defaultThemeColors.nut,
  };

  return (
    <div className={styles.container} style={themeColors as ThemeColors}>
      <div
        className={classnames(styles.block, {
          [styles.isB2B]: isB2B(),
        })}
      >
        {banner.logoUrl && (
          <div className={styles.brand}>
            <Image
              className={styles.brandImage}
              src={banner.logoUrl}
              alt="brand-logo"
              height={undefined}
              width={undefined}
            />
          </div>
        )}
        <div className={styles.textContainer}>
          <span className={styles.title}>{banner.title || ''}</span>
          <Span className={styles.subtitle}>{banner.subtitle || ''}</Span>
        </div>
      </div>
      {banner.image && (
        <Image
          width={SIZES.small.ldpi.width}
          height={SIZES.small.ldpi.height}
          loading="eager"
          alt={banner.image.altText}
          src={`${banner.image.mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover`}
          srcSet={`${banner.image.mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover 1x, ${banner.image.mobileUrl}?w=${SIZES.small.hdpi.width}&h=${SIZES.small.hdpi.height}&fit=cover 2x`}
          className={styles.image}
        >
          <source
            srcSet={`${banner.image.desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover&format=webp 1x, ${banner.image.desktopUrl}?w=${SIZES.large.hdpi.width}&h=${SIZES.large.hdpi.height}&fit=cover&format=webp 2x`}
            media="(min-width: 1024px)"
            type="image/webp"
          />
          <source
            srcSet={`${banner.image.desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover 1x, ${banner.image.desktopUrl}?w=${SIZES.large.hdpi.width}&h=${SIZES.large.hdpi.height}&fit=cover 2x`}
            media="(min-width: 1024px)"
          />
          <source
            srcSet={`${banner.image.desktopUrl}?w=${SIZES.medium.ldpi.width}&h=${SIZES.medium.ldpi.height}&fit=cover&format=webp 1x, ${banner.image.desktopUrl}?w=${SIZES.medium.hdpi.width}&h=${SIZES.medium.hdpi.height}&fit=cover&format=webp 2x`}
            media="(min-width: 536px)"
            type="image/webp"
          />
          <source
            srcSet={`${banner.image.desktopUrl}?w=${SIZES.medium.ldpi.width}&h=${SIZES.medium.ldpi.height}&fit=cover 1x, ${banner.image.desktopUrl}?w=${SIZES.medium.hdpi.width}&h=${SIZES.medium.hdpi.height}&fit=cover 2x`}
            media="(min-width: 536px)"
          />
          <source
            srcSet={`${banner.image.mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover&format=webp 1x, ${banner.image.mobileUrl}?w=${SIZES.small.hdpi.width}&h=${SIZES.small.hdpi.height}&fit=cover&format=webp 2x`}
            type="image/webp"
          />
        </Image>
      )}
    </div>
  );
};

BrandBlock.displayName = 'BrandBlock';
