export const GTM_DISPLAY_POPIN_INFO = (
  spaceSlug: string,
  pageType: string,
) => ({
  event: 'interaction_display popin info',
  event_trigger: 'click',
  amp_event_name: 'Display popin info',
  label_name: 'sponsored label',
  space_slug: spaceSlug,
  page_type: pageType,
});
