import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { Image } from '@/core/Image/Image';
import { isB2B } from '@/core/settings/utils';
import { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { PartnerAdBannerLabel } from '@/sellerAdsManagement/PartnerAdBannerCampaign/components/PartnerAdBannerLabel/PartnerAdBannerLabel';
import {
  defaultThemeColors,
  ThemeColors,
} from '@/sellerAdsManagement/PartnerAdBannerCampaign/interfaces/themeColors';
import { usePartnerAdTheme } from '@/sellerAdsManagement/PartnerAdTheme/hooks/usePartnerAdTheme';

import styles from './RectangularBlock.module.scss';

export interface RectangularBlockProps {
  banner: BannerCampaign;
}

const SIZES = {
  small: {
    ldpi: {
      width: 228,
      height: 156,
    },
    hdpi: {
      width: 456,
      height: 312,
    },
  },
  medium: {
    ldpi: {
      width: 228,
      height: 156,
    },
    hdpi: {
      width: 456,
      height: 312,
    },
  },
  large: {
    ldpi: {
      width: 228,
      height: 156,
    },
    hdpi: {
      width: 456,
      height: 312,
    },
  },
};

export const RectangularBlock: FunctionComponent<RectangularBlockProps> = ({
  banner,
}) => {
  const theme = usePartnerAdTheme();

  const themeColors = {
    '--banner-main-color':
      theme && theme.colors ? theme.colors.main : defaultThemeColors.main,
    '--banner-accent-color':
      theme && theme.colors ? theme.colors.accent : defaultThemeColors.accent,
    '--banner-labor_text-color':
      theme && theme.colors
        ? theme.colors.labor_text
        : defaultThemeColors.labor_text,
    '--banner-secondary-color':
      theme && theme.colors
        ? theme.colors.secondary
        : defaultThemeColors.secondary,
  };

  return (
    <div
      className={classnames(styles.container, { [styles.isB2B]: isB2B() })}
      style={themeColors as ThemeColors}
    >
      {banner.label && (
        <PartnerAdBannerLabel
          label={banner.label}
          subtitleClassName={styles.subtitle}
          lineClassName={styles.line}
          middleLineClassName={styles.middleLine}
          smallLineClassName={styles.smallLine}
          bigLineClassName={styles.bigLine}
          labelClassName={styles.label}
          subtitle={banner.subtitle}
        />
      )}
      <div className={styles.imgContainer}>
        {banner.logoUrl && (
          <div className={styles.brand}>
            <Image
              className={styles.brandImage}
              src={banner.logoUrl}
              alt="brand-logo"
              height={undefined}
              width={undefined}
            />
          </div>
        )}
        <Image
          width={SIZES.small.ldpi.width}
          height={SIZES.small.ldpi.height}
          loading="eager"
          alt={banner.image.altText}
          src={`${banner.image.mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover`}
          srcSet={`${banner.image.mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover 1x, ${banner.image.mobileUrl}?w=${SIZES.small.hdpi.width}&h=${SIZES.small.hdpi.height}&fit=cover 2x`}
          className={styles.image}
        >
          <source
            srcSet={`${banner.image.desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover&format=webp 1x, ${banner.image.desktopUrl}?w=${SIZES.large.hdpi.width}&h=${SIZES.large.hdpi.height}&fit=cover&format=webp 2x`}
            media="(min-width: 1024px)"
            type="image/webp"
          />
          <source
            srcSet={`${banner.image.desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover 1x, ${banner.image.desktopUrl}?w=${SIZES.large.hdpi.width}&h=${SIZES.large.hdpi.height}&fit=cover 2x`}
            media="(min-width: 1024px)"
          />
          <source
            srcSet={`${banner.image.desktopUrl}?w=${SIZES.medium.ldpi.width}&h=${SIZES.medium.ldpi.height}&fit=cover&format=webp 1x, ${banner.image.desktopUrl}?w=${SIZES.medium.hdpi.width}&h=${SIZES.medium.hdpi.height}&fit=cover&format=webp 2x`}
            media="(min-width: 536px)"
            type="image/webp"
          />
          <source
            srcSet={`${banner.image.desktopUrl}?w=${SIZES.medium.ldpi.width}&h=${SIZES.medium.ldpi.height}&fit=cover 1x, ${banner.image.desktopUrl}?w=${SIZES.medium.hdpi.width}&h=${SIZES.medium.hdpi.height}&fit=cover 2x`}
            media="(min-width: 536px)"
          />
          <source
            srcSet={`${banner.image.mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover&format=webp 1x, ${banner.image.mobileUrl}?w=${SIZES.small.hdpi.width}&h=${SIZES.small.hdpi.height}&fit=cover&format=webp 2x`}
            type="image/webp"
          />
        </Image>
      </div>
    </div>
  );
};

RectangularBlock.displayName = 'RectangularBlock';
