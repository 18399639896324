import dynamic from 'next/dynamic';
import { useState, type FunctionComponent } from 'react';

import { useTrackViewedElement } from '@/core/tracking/hooks/useTrackViewedElement';
import { Image } from '@/domains/core/Image/Image';
import { Link } from '@/domains/core/routing/components/Link';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import type { CampaignModalProps } from '@/domains/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { GTM_CLICK_ON_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/clickOnBannerCampaign';
import { GTM_DISPLAY_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/displayBannerCampaign';

const DynamicModal = dynamic<CampaignModalProps>(
  async () =>
    (
      await import(
        '@/domains/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal'
      )
    ).CampaignModal,
);

interface ListingCardImageProps {
  campaign: BannerCampaign;
  className?: string;
  bannerType: 'bottom' | 'middle';
}

export const SIZES = {
  small: {
    ldpi: {
      width: 328,
      height: 393,
    },
    hdpi: {
      width: 656,
      height: 786,
    },
  },
  medium: {
    ldpi: {
      width: 380,
      height: 411,
    },
    hdpi: {
      width: 760,
      height: 822,
    },
  },
  large: {
    ldpi: {
      width: 510,
      height: 411,
    },
    hdpi: {
      width: 1020,
      height: 822,
    },
  },
};

export const BREAKPOINTS = {
  medium: 543,
  large: 1024,
};

export const ListingCardImage: FunctionComponent<ListingCardImageProps> = ({
  campaign,
  className,
  bannerType,
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [impressionDone, setImpressionDone] = useState(false);

  const [campaignRef] = useTrackViewedElement(
    () => {
      Gtm.push(
        GTM_DISPLAY_BANNER_CAMPAIGN(
          `${bannerType}_banner`,
          campaign.id,
          campaign.name,
          campaign.link.url || '',
          0,
          !!(campaign.sponsor && campaign.advertiser),
          campaign.pageType || undefined,
          campaign.trackingId || undefined,
          'Manomano',
        ),
      );
      setImpressionDone(true);
    },
    { delay: 1000, threshold: 0.5 },
  );

  const trackClick = () => {
    if (!impressionDone) {
      Gtm.push(
        GTM_DISPLAY_BANNER_CAMPAIGN(
          `${bannerType}_banner`,
          campaign.id,
          campaign.name,
          campaign.link.url || '',
          0,
          !!(campaign.sponsor && campaign.advertiser),
          campaign.pageType || undefined,
          campaign.trackingId || undefined,
          'Manomano',
        ),
      );
    }

    Gtm.push(
      GTM_CLICK_ON_BANNER_CAMPAIGN(
        `${bannerType}_banner`,
        campaign.id,
        campaign.name,
        campaign.link && campaign.link.url ? campaign.link.url : '',
        0,
        !!(campaign.sponsor && campaign.advertiser),
        campaign.pageType || undefined,
        campaign.trackingId || undefined,
        'Manomano',
      ),
    );

    setImpressionDone(true);
  };

  const { link, image } = campaign;

  const handleClick = () => {
    setDisplayModal(true);
    trackClick();
  };

  const imgElt = image && (
    <Image
      width={SIZES.small.ldpi.width}
      height={SIZES.small.ldpi.height}
      loading="eager"
      alt={image.altText}
      src={`${image.mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover`}
      srcSet={`${image.mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover 1x, ${image.mobileUrl}?w=${SIZES.small.hdpi.width}&h=${SIZES.small.hdpi.height}&fit=cover 2x`}
      className={className}
    >
      <source
        srcSet={`${image.desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover&format=webp 1x, ${image.desktopUrl}?w=${SIZES.large.hdpi.width}&h=${SIZES.large.hdpi.height}&fit=cover&format=webp 2x`}
        media={`(min-width: ${BREAKPOINTS.large}px)`}
        type="image/webp"
      />
      <source
        srcSet={`${image.desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover 1x, ${image.desktopUrl}?w=${SIZES.large.hdpi.width}&h=${SIZES.large.hdpi.height}&fit=cover 2x`}
        media={`(min-width: ${BREAKPOINTS.large}px)`}
      />
      <source
        srcSet={`${image.desktopUrl}?w=${SIZES.medium.ldpi.width}&h=${SIZES.medium.ldpi.height}&fit=cover&format=webp 1x, ${image.desktopUrl}?w=${SIZES.medium.hdpi.width}&h=${SIZES.medium.hdpi.height}&fit=cover&format=webp 2x`}
        media={`(min-width: ${BREAKPOINTS.medium}px)`}
        type="image/webp"
      />
      <source
        srcSet={`${image.desktopUrl}?w=${SIZES.medium.ldpi.width}&h=${SIZES.medium.ldpi.height}&fit=cover 1x, ${image.desktopUrl}?w=${SIZES.medium.hdpi.width}&h=${SIZES.medium.hdpi.height}&fit=cover 2x`}
        media={`(min-width: ${BREAKPOINTS.medium}px)`}
      />
      <source
        srcSet={`${image.mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover&format=webp 1x, ${image.mobileUrl}?w=${SIZES.small.hdpi.width}&h=${SIZES.small.hdpi.height}&fit=cover&format=webp 2x`}
        type="image/webp"
      />
    </Image>
  );

  const twLinkClasses = 'block m-0 p-0 border-0 w-full h-full cursor-pointer';

  if (!link || !link.url) {
    return (
      <span data-testid="no-link" className={twLinkClasses}>
        {imgElt}
      </span>
    );
  }

  return (
    <>
      {link.target !== '_modal' ? (
        <Link
          data-testid="link"
          href={link.url}
          target={link.target || '_self'}
          ref={campaignRef}
          onClick={trackClick}
          className={twLinkClasses}
        >
          {imgElt}
        </Link>
      ) : (
        <>
          <button
            data-testid="link-modal"
            className={twLinkClasses}
            onClick={handleClick}
            type="button"
            ref={campaignRef}
          >
            {imgElt}
          </button>
          {displayModal && (
            <DynamicModal
              url={link.url}
              onClose={() => setDisplayModal(false)}
            />
          )}
        </>
      )}
    </>
  );
};
