import { CSSProperties } from 'react';

import { isB2C } from '@/core/settings/utils';

export interface ThemeColors extends CSSProperties {
  '--banner-main-color': string;
  '--banner-accent-color': string;
  '--banner-labor_text-color': string;
  '--banner-nut-color': string;
  '--banner-secondary-color': string;
}

export const defaultThemeColors = isB2C()
  ? {
      main: '#ff991f',
      accent: '#561607',
      secondary: '#ffe5Bd',
      labor_text: '#000000',
      nut: '#ffb762',
    }
  : {
      main: '#0d4465',
      accent: '#e9f845',
      secondary: '#dbf6ff',
      labor_text: '#ffffff',
      nut: '#dbf6ff',
    };
