const getAttributes = (campaignType: string, campaignName: string) => {
  switch (campaignType) {
    case 'slider_banner':
      return {
        e_action: 'campaign display on next slider control',
        e_label: campaignType,
      };
    case 'secondary_banner':
      return {
        e_action: 'campaign display',
        e_label: 'secondary banner',
      };
    case 'tertiary_banner':
      return {
        e_action: 'campaign display',
        e_label: 'tertiary banner',
      };
    case 'promo_block':
      return {
        e_action: 'campaign display promotion block',
        e_label: campaignType,
      };
    case 'middle_banner':
    case 'bottom_banner':
      return {
        e_action: 'promotion impressions',
        e_label: campaignName,
      };
    case 'onglet_banner':
      return {
        e_action: 'display campaign',
        e_label: campaignName,
      };
    case 'burger_menu_brand_block':
      return {
        e_action: 'campaign display brand block',
        e_label: campaignName,
      };
    default:
      break;
  }
  return { e_action: undefined, e_label: undefined };
};
export const GTM_DISPLAY_BANNER_CAMPAIGN = (
  campaignType: string,
  campaignId: string,
  campaignName: string,
  campaignUrl: string,
  position: number,
  hasSponsoredLabel: boolean,
  campaignPageType?: string,
  campaignTrackingId?: string,
  campaignSource?: 'Manomano' | 'Unlimitail',
) => {
  const variableAttributes = getAttributes(campaignType, campaignName);
  return {
    amp_event_name: 'View promotion',
    banner_id: campaignId,
    creative_name: campaignName,
    creative_position: position + 1,
    creative_slot: campaignType,
    e_category: 'ecommerce',
    event: 'interaction_view promotion',
    has_sponsored_label:
      campaignSource === 'Unlimitail' ? true : hasSponsoredLabel,
    promotion_id: campaignTrackingId || '',
    promotion_name: campaignPageType || '',
    promotion_source: campaignSource || 'Manomano',
    target_url: campaignUrl,
    ...variableAttributes,
  };
};
